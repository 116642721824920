import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TRANSLATIONS_NAMESPACE } from '../../constants/global'
import { getErrorText, PSP } from '../../util/validators'
import {
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    CircularProgress,
    makeStyles,
} from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import { ServicePartner } from '../../api'
import { pspErrorMessage } from '../../types/reduxNotificationTypes'
import { useDispatchNotification } from '../../hooks/useDispatchNotification'
import { useSelector } from 'react-redux'
import InputTooltip from '../Tooltip/InputTooltip'

const createPspName = (psp) =>
    psp ? [psp.name, psp.address.street, psp.address.city, psp.address.zip].join(', ') : ''

const useSelectStyles = makeStyles({
    icon: {
        right: '25px',
    },
})

export default function ServicePartnerSearchInput({ disabled, orderDetails, setPsp, errors }) {
    const [query, setQuery] = useState(orderDetails.preferredServicePartnerName || '')
    const [servicePartners, setServicePartners] = useState([])
    const [isSelectOpen, setIsSelectOpen] = useState(false)
    const [isServicePartnerListBeingClosed, setIsServicePartnerListBeingClosed] = useState(false)
    const [isServicePartnersLoading, setIsServicePartnersLoading] = useState(false)
    const [wasPSPChanged, setWasPSPChanged] = useState(!!orderDetails.preferredServicePartnerId)
    const [dispatchNotification] = useDispatchNotification()
    const queryInputRef = useRef(null)
    const selectClasses = useSelectStyles()
    const userInfo = useSelector((state) => state.oidc.userInfo)
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const queryDebounceHandle = useRef(0)

    const closeServicePartnersListHandle = () => {
        setServicePartners([])
        setIsServicePartnerListBeingClosed(true)
        setQuery('')
        setPsp(null, null)
    }

    const clearQueryHandle = () => {
        setQuery('')
        queryInputRef.current.focus()
        setPsp(null, null)
    }

    const servicePartnerOnChangeHandle = (e) => {
        const spId = e.target.value
        const selectedPsp = servicePartners.find((item) => item.id === spId)
        const pspName = createPspName(selectedPsp)
        setQuery(pspName)
        setPsp(spId, pspName)
    }

    const getServicePartners = useCallback(
        async (pspQuery) => {
            setIsServicePartnersLoading(true)
            try {
                const response = await ServicePartner.searchServicePartner(pspQuery)
                setServicePartners(response.data)
                if (response.data.length === 0) {
                    dispatchNotification(pspErrorMessage)
                } else {
                    setIsSelectOpen(true)
                }
            } catch (e) {
                console.error(e)
                setServicePartners([])
            }
            setIsServicePartnersLoading(false)
        },
        [dispatchNotification]
    )

    const queryOnChangeHandle = (e) => {
        e.persist()
        setQuery(e.target.value)
        if (e.target.value.length >= 3) {
            window.clearTimeout(queryDebounceHandle.current)
            queryDebounceHandle.current = window.setTimeout(() => {
                getServicePartners(e.target.value)
                queryDebounceHandle.current = 0
            }, 700)
        }
    }

    useEffect(() => {
        if (isServicePartnerListBeingClosed) {
            queryInputRef.current.focus()
            setIsServicePartnerListBeingClosed(false)
        }
    }, [isServicePartnerListBeingClosed])

    useEffect(() => {
        if (!disabled && !orderDetails.preferredServicePartnerId && !wasPSPChanged) {
            setWasPSPChanged(true)
            const getDealerServicePartner = async () => {
                try {
                    const sp = await ServicePartner.getServicePartner(userInfo.dealershipId)
                    if (sp?.data) {
                        const pspName = createPspName(sp.data)
                        const spId = sp.data.id
                        setPsp(spId, pspName)
                        setServicePartners([sp.data])
                    }
                } catch (e) {
                    console.error(e)
                }
            }
            getDealerServicePartner()
        }
    }, [
        disabled,
        orderDetails.preferredServicePartnerId,
        setPsp,
        userInfo.dealershipId,
        wasPSPChanged,
    ])

    useEffect(() => {
        setServicePartners([])
        setQuery(orderDetails.preferredServicePartnerName || '')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled])

    return (
        <FormControl error={!!errors.psp} disabled={disabled} fullWidth>
            <InputLabel shrink>
                <Grid container alignItems={'center'} spacing={1} wrap={'nowrap'}>
                    <Grid item>{t('general.order.psp')}</Grid>
                    <Grid item>
                        <InputTooltip text={t('general.order.psp.tooltip')} />
                    </Grid>
                </Grid>
            </InputLabel>
            {servicePartners.length === 0 || disabled ? (
                <Input
                    value={query}
                    onChange={queryOnChangeHandle}
                    inputRef={queryInputRef}
                    endAdornment={
                        !disabled ? (
                            <InputAdornment position="end">
                                {isServicePartnersLoading ? (
                                    <CircularProgress size={12} />
                                ) : (
                                    <IconButton onClick={clearQueryHandle}>
                                        <Close />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ) : undefined
                    }
                />
            ) : (
                <Select
                    classes={{
                        icon: selectClasses.icon,
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton onClick={closeServicePartnersListHandle}>
                                <Close />
                            </IconButton>
                        </InputAdornment>
                    }
                    open={isSelectOpen}
                    onOpen={() => setIsSelectOpen(true)}
                    onClose={() => setIsSelectOpen(false)}
                    value={orderDetails.preferredServicePartnerId || ''}
                    onChange={servicePartnerOnChangeHandle}
                >
                    {servicePartners &&
                        servicePartners.map((item) => (
                            <MenuItem value={item.id} key={item.id}>
                                {createPspName(item)}
                            </MenuItem>
                        ))}
                </Select>
            )}
            {errors.psp && <FormHelperText>{t(getErrorText(PSP))}</FormHelperText>}
        </FormControl>
    )
}
